<template>
  <div>
    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full">
        <label> 1- Exam access Type: </label>
      </div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <ul class="centerx">
          <li>
            <vs-radio v-model="radios1" vs-value="1"
              >Use same access for the students</vs-radio
            >
          </li>
          <li>
            <vs-radio v-model="radios1" vs-value="2"
              >Create private access code</vs-radio
            >
          </li>
        </ul>
        <div>
          <ul class="con-s">
            <li>
              <label>Hide Students identities </label>
              <vs-switch v-model="switch1" />
            </li>
          </ul>
        </div>
        <div>
          <ul class="con-s">
            <li>
              <label>Students will access the exam during</label>
              <vs-switch v-model="switch2" />
            </li>
          </ul>
          <!-- <label> exam start time </label>
          <flat-pickr :config="config" class="w-full" name="date"> </flat-pickr> -->
          <div class="vx-row">
            <div class="vx-col lg:w-1/3 mt-3">
              <vs-input class="inputx w-full" placeholder="HH" />
            </div>
            <div class="vx-col lg:w-1/3 mt-3">
              <vs-input class="inputx w-full" placeholder="MM" />
            </div>
          </div>

          <small>
            if disabled, the exam will let students access the exam in 5 minutes
            after exam start time This feature is not valid for the exam with
            fixed period
          </small>
        </div>
      </div>
    </div>
    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-2/5 w-full">
        <label> 2- Choose Exam attendees : </label>
      </div>
    </div>
    <div class="vx-row mt-5 items-center">
      <div class="vx-col w-full">
        <data-table
          @startDownload="startDownload"
          :csv_url="'/subject/export'"
          :pdf_url="'subjects-file/pdf'"
          :headers_prop="headers"
          list_url_prop="/subjects"
          :allow_print_pdf="false"
        >
          <template slot="center_content">
            <!--  TODO: add the appropriate export element -->
            <!--            <h3>Center Content</h3>-->
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/general/datatable/DataTable2.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    flatPickr,
    DataTable
  },
  data () {
    return {
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true
      },
      radios1: '1',
      switch1: false,
      switch2: false,
      headers: [
        {
          headerName:this.$t('basic.ID'),

          field: 'id',
          checkboxSelection: true,
          width: 200,
          minWidth: 160,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          headerName: this.$t('subject.name'),
          field: '',
          minWidth: 160
        },
        {
          headerName: this.$t('basic.email'),
          field: 'email',
          minWidth: 160
        },

        {
          headerName: this.$t('subject.Actions'),
          field: 'transactions',
          width: 240,
          minWidth: 160,
          cellRendererFramework: 'CellRendererActionsSubject',
          cellRendererParams: {
            actions_prop: [
              // {title: 'Report', route_name:'update-round-manager', parameter_name:"id", icon:'Edit3Icon', navigate_action:false},
              // {title: 'RevokeAccess', route_name:'show-round-manager', parameter_name:"id", icon: 'Edit3Icon', navigate_action:false},
              {
                title: this.$t('main.Edit'),
                route_name: 'edit-Subjects',
                parameter_name: 'id',
                icon: 'Edit3Icon',
                navigate_action: true
              },

              {
                title: this.$t('basic.show'),
                route_name: 'show-Subjects',
                parameter_name: 'id',
                icon: 'EyeIcon',
                navigate_action: true
              },
              {
                title: this.$t('basic.actions'),
                dropDown: true,
                parameter_name: 'id',
                icon: 'MoreVerticalIcon',
                navigate_action: false
              }
              // {title: 'Deactivate',action: "deactivate", resource:"round", parameter_name:"id", icon: 'SlashIcon', navigate_action:false}
            ]
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
